


















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { ApiResponse } from '@/components/types';
import BankSelect from "@/modules/bank/components/BankSelect.vue";
import { PERSIST_TAN_MODE, REQUEST_TAN_MODES } from "@/modules/listOfAssets/store/financeAccount";
import {PersistTanMode, TanModeOption, TanModeRequest, TanModeResponse} from "@/modules/listOfAssets/types";
import BankUseSelect from "@/modules/bank/components/BankUseSelect.vue";
import AppSelect from "@/components/form/AppSelect.vue";

const Import = namespace('listOfAssets/financeAccount');

@Component({
  components: {
    AppSelect,
    BankUseSelect,
    BankSelect,
    ErrorAlert
  }
})
export default class TanModeDialog extends Vue {
  @Import.Action(REQUEST_TAN_MODES) requestTanModes!: (params: TanModeRequest) => Promise<ApiResponse<TanModeResponse>>;
  @Import.Action(PERSIST_TAN_MODE) persistTan!: (params: PersistTanMode) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  loading: boolean = false;
  loaded: boolean = false;

  options: TanModeOption[] = [];
  mediums: string[] = []

  value: TanModeRequest = {
    bank_code: '',
    username: '',
    pin: '',
  };

  persist: PersistTanMode = {
    employee_id: null,
    bank_code: '',
    username: '',
    kundensystem_id: null,
    tan_mode: {
      id: '',
      name: '',
      medium: null,
    }
  };

  @Watch('dialog')
  watchDialogClosed() {
    setTimeout(() => {
      this.error = null;
      this.loading = false;
      this.loaded = false;

      this.value = {
        bank_code: '',
        username: '',
        pin: '',
      };

      this.persist = {
        employee_id: null,
        bank_code: '',
        username: '',
        kundensystem_id: null,
        tan_mode: {
          id: '',
          name: '',
          medium: null,
        }
      };
      // @ts-ignore
      this.$refs.form && this.$refs.form.reset();
      // @ts-ignore
      this.$refs.persist && this.$refs.persist.reset();
    }, 200);
  }

  @Watch('persist.tan_mode.id')
  watchTanMode(id?: number) {
    if (!id) return;

    const mode = this.options.find((item) => item.id === id);

    if (!mode) return;

    this.persist.tan_mode.name = mode.name;

    if (mode.media.length === 1) {
      this.persist.tan_mode.medium = mode.media[0].name;
    }

    this.mediums = mode.media.map((m) => m.name);
  }

  onSuccess() {
    this.$emit('success');
    this.dialog = false
  }

  async request() {
    this.error = null;

    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result) return;

    this.loading = true;

    let { content, error } = await this.requestTanModes(this.value);

    this.loading = false;

    if (error) {
      this.error = error;
      return;
    }

    if (!content) {
      content = { tanModes: [], kundensystemId: null }
    }

    this.persist.bank_code = this.value.bank_code;
    this.persist.username = this.value.username;
    this.persist.kundensystem_id = content.kundensystemId

    this.options = content.tanModes || [];
    this.loaded = true;
  }

  save() {
    this.error = null;

    return this.persistTan(this.persist);
  }
}
