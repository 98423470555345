


























































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PaginatableMixin } from '@/views/mixin';
import { ApiResponse, Pagination } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import AppTable from '@/components/AppTable.vue';
import CardHeader from '@/components/CardHeader.vue';
import { FETCH_TAN_MODE_LIST } from "@/modules/listOfAssets/store/financeAccount";
import { TanConfiguration } from "@/modules/listOfAssets/types";
import TanModeDialog from "@/modules/listOfAssets/components/TanModeDialog.vue";
import DeleteTanModeDialog from "@/modules/listOfAssets/components/DeleteTanModeDialog.vue";
import { GET_LIST } from "@/modules/bank/store";
import { Bank } from "@/modules/bank/types";

const FinanceAccount = namespace('listOfAssets/financeAccount');
const Bank = namespace('bank');

@Component({
  components: {
    DeleteTanModeDialog,
    TanModeDialog,
    CardHeader,
    AppTable,
    ErrorAlert,
  }
})
export default class List extends PaginatableMixin {
  @FinanceAccount.Action(FETCH_TAN_MODE_LIST) fetch!: () => Promise<ApiResponse<TanConfiguration[]>>;
  @Bank.Getter(GET_LIST) banks!: Bank[];

  error: any = null;
  loading: boolean = false;
  list: TanConfiguration[] = [];
  deleteConfig: TanConfiguration | null = null;

  pagination: Pagination = {
    sortBy: ['bank'],
    itemsPerPage: 10,
    page: 1,
    descending: false
  };

  setPage(page: number) {}

  get items() {
    return this.list.map(item => {
      const bank = (this.banks.find(bank => bank.bankCode === item.bankCode) || {}).name

      return { ...item, bank }
    });
  }

  get headers() {
    return [
      { align: 'left', text: 'Bank', value: 'bank' },
      { align: 'left', text: 'Username', value: 'username' },
      { align: 'left', text: 'Tan Verfahren', value: 'tanMode.name', sortable: false },
      { align: 'left', text: 'Tan Medium', value: 'tanMode.medium', sortable: false },
      { align: 'left', text: 'Aktionen', sortable: false },
    ];
  }

  async load() {
    this.loading = true;

    try {
      const { content } = await this.fetch();

      this.list = content || [];
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }

  created() {
    this.load()
  }
}
