



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { DeleteTanMode, TanConfiguration } from "@/modules/listOfAssets/types";
import { DELETE_TAN_MODE } from "@/modules/listOfAssets/store/financeAccount";

const Import = namespace('listOfAssets/financeAccount');

@Component({})
export default class DeleteTanModeDialog extends Vue {
  @Prop({ type: Object }) tanConfig!: TanConfiguration | null;

  @Import.Action(DELETE_TAN_MODE) deleteTanMode!: (tanMode: DeleteTanMode) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  value: DeleteTanMode | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:tagConfig', null);
    }, 200);
  }

  @Watch('tanConfig')
  watchTan(tanConfig: TanConfiguration) {
    if (!tanConfig) return;

    this.value = {
      username: tanConfig.username,
      bank_code: tanConfig.bankCode,
      employee_id: tanConfig.employeeId,
    };

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  onSuccess() {
    this.$emit('success');
    this.dialog = false
  }

  save() {
    if (!this.value) return;

    this.error = null;

    return this.deleteTanMode(this.value);
  }
}
