














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AppSelect extends Vue {
  @Prop({ type: [String, Number] }) value?: string | number;
  @Prop({ type: String, required: true }) label?: string;
  @Prop({ type: Array, default: () => [] }) items!: any[];
  @Prop({ type: Boolean, default: false }) required!: boolean;

  loading = false;

}
